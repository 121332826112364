<template>
  <div ref="customEntryRef">
    <a-modal
        :visible="visible"
        :getContainer="()=>$refs.customEntryRef"
        title="自定义快捷入口"
        width="40.6vw"
        centered
        @cancel="closeModal"
    >
      <template #footer>
        <div class="btn-left">
          <Tooltip :title="'重置恢复默认值'">
            <a-button type="primary" ghost @click="reset">重置</a-button>
          </Tooltip>
          <span class="font-blue">最多选中5个快捷入口</span>
        </div>
        <a-button class="gray" @click="closeModal">取消</a-button>
        <a-button type="primary" :disabled="dataList.length===0" @click="saveModal">确定</a-button>
      </template>
      <div class="box-main">
        <div class="box-item box-left">
          <div class="tt">
            <span>模块列表</span>
            <span class="num">{{leftNum}}</span>
          </div>
          <div class="search">
            <a-input-search
                allowClear
                v-model:value="leftValue"
                placeholder="搜索"
                style="width: 100%"
                @change="clearLeft"
                @search="onSearchLeft"
            />
          </div>
          <div class="tree-title box-margin">模块名</div>
          <div class="tree-box">
            <a-tree
                checkable
                defaultExpandAll
                :replace-fields="{key:'id',children:'childMenus',value:'id'}"
                :tree-data="leftValue?searchData:treeData"
                v-model:expandedKeys="expandedKeys"
                v-model:selectedKeys="selectedKeys"
                v-model:checkedKeys="checkedKeys"
                :checkStrictly="true"
                @check="checkTree"
            >
            </a-tree>
          </div>
        </div>
        <div class="box-item box-right">
          <div class="tt">
            <span>已选择的快捷入口</span>
            <span class="num back-color">{{ dataList.length }}</span>
          </div>
          <div class="search">
            <a-input-search
                allowClear
                v-model:value="rightValue"
                placeholder="搜索"
                style="width: 100%"
                @change="clearRight"
                @search="onSearchRight"
            />
          </div>
          <div class="tree-box box-margin">
            <a-table :customRow="customRow" :rowKey="rowKey" :dataSource="rightValue?searchDataList:dataList" :columns="columns" :pagination="false">
              <template #action="{record, index }">
                <a-space class="flex-box">
                  <Tooltip :title="'删除'">
                    <span class="operationLinkdel icon-btn" @click="handleDel(record,index)"><CloseCircleFilled :style="{display:'block',color:'#B3B3B3'}" /></span>
                  </Tooltip>
                </a-space>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted, watch,
} from "vue";
import {CloseCircleFilled} from '@ant-design/icons-vue';
import Tooltip from '@/components/Tooltip'
import {useGlobalPropertyHook} from '@/hooks/common'
import {debounce} from 'lodash'
const treeData = ref([]);
const searchData = ref([]);
const defaultList = ref([])
const searchDataList = ref([]);
const dataList = ref([])
const columns = ref([
  {
    title: "模块名",
    dataIndex: "permName",
    key: 'permName',
    ellipsis: true,
    /*customRender: ({record}) => {
      if(record.secondMenu){
        return record.secondMenu
      }else{
        return record.firstMenu
      }
    }*/
  },
  {
    title: "操作",
    dataIndex: "option",
    width: 80,
    align: 'center',
    ellipsis: true,
    slots: { customRender: "action" },
    fixed: "right",
  },
])
export default defineComponent({
  name: "CustomEntry",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ['closeModal','updateList'],
  components: {CloseCircleFilled,Tooltip},
  setup(props,ctx) {
    const { $api } = useGlobalPropertyHook();
    /*const state = reactive({
      userData: {}
    })*/
    const menuList = ref([])
    const getMenus = () => {
      dataList.value = []
      $api.menuPerms().then((res) => {
        treeData.value = renderTreeNodes(menuTreeToList(res.data))
        const list = treeToList(treeData.value,'childMenus')
        menuList.value = fuzzyQuery(list,leftValue.value,'permName')
        searchData.value = menuList.value
        leftNum.value = menuList.value.length
        props.checkList.filter((items)=>{
          let id = items.secondMenuId?items.secondMenuId:items.firstMenuId
          menuList.value.forEach((item)=>{
            if(id === item.id){
              dataList.value.push(item)
            }
          })
        })
        let checkedArr = dataList.value.map((item)=>{
          return item.id
        })
        checkedKeys.value = {
          checked: checkedArr
        }
        if(checkedKeys.value.length===5){
          disabledTreeNodes(treeData.value,checkedKeys.value)
        }
      });
    };
    const menuTreeToList = data => {
      const list = data.map(items=>{
        let childMenus
        if (items.childMenus && items.childMenus.length) {
          childMenus = items.childMenus.map(item=>{
            delete item.childMenus
            return item
          })
        }
        return {
          ...items,
          childMenus: childMenus
        }
      })
      return list
    }
    const renderTreeNodes = data => {
      const setData = data.map(item => {
        if (item.childMenus && item.childMenus.length) {
          item.checkable = false
          item.disabled = false
          renderTreeNodes(item.childMenus);
        } else{
          item.disabled = false
        }
        return item;
      });
      return setData;
    }
    const disabledTreeNodes = (data,chenckList) => {
      const setData = data.map(item => {
        if (item.childMenus && item.childMenus.length) {
          item.disabled = true
          disabledTreeNodes(item.childMenus,chenckList);
        } else{
          chenckList.indexOf(item.id) > -1 ? '' : item.disabled = true
        }
        return item;
      });
      return setData;
    }
    const leftValue = ref('')
    const rightValue = ref()
    const expandedKeys = ref();
    const selectedKeys = ref();
    const checkedKeys = ref();
    watch(checkedKeys, (val) => {
      const {checked} = val
      if (checked&&checked.length===5){
        treeData.value = disabledTreeNodes(treeData.value,checked)
        searchData.value = disabledTreeNodes(searchData.value,checked)
      } else {
        treeData.value = renderTreeNodes(treeData.value)
        searchData.value = renderTreeNodes(searchData.value)
      }
    });
    const leftNum = ref()
    const reset = ()=>{
      ctx.emit('resetList');
    }
    const closeModal = ()=>{
      ctx.emit('closeModal',false);
    }
    const saveModal = ()=>{
      const params = ref([])
      dataList.value.forEach((item,index)=>{
        params.value.push({
          firstMenu: item.parentId,
          secondMenu: item.id,
          sort: index+1+''
        })
      })
      $api.saveQetQueryQuickEntry(params.value).then(()=>{
        ctx.emit('updateList');
        ctx.emit('closeModal',true);
      })
    }
    const clearLeft = debounce(() => {
      searchData.value=[]
      onSearchLeft()
    },500)
    const onSearchLeft = () => {
      if(leftValue.value){
        const list = treeToList(treeData.value,'childMenus')
        const newList = fuzzyQuery(list,leftValue.value,'permName')
        searchData.value = newList
      }
    }
    const clearRight = debounce(() => {
      searchDataList.value=[]
      onSearchRight()
    },500)
    const onSearchRight = () => {
      if(rightValue.value){
        const newList = fuzzyQuery(dataList.value,rightValue.value,'permName')
        searchDataList.value = newList
      }
    }
    /**
     * 使用indexof方法实现模糊查询
     * @param  {Array}  list     进行查询的数组
     * @param  {String} keyWord  查询的关键词
     * @return {Array}           查询的结果
     */
    const fuzzyQuery = (list, keyWord, listWord)=> {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i][listWord].indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      return arr;
    }
    const checkTree = (selectedKeys) => {
      const {checked} = selectedKeys
      /*if (checked.length==5){
        treeData.value = disabledTreeNodes(treeData.value,checked)
        searchData.value = disabledTreeNodes(searchData.value,checked)
      } else {
        treeData.value = renderTreeNodes(treeData.value)
        searchData.value = renderTreeNodes(searchData.value)
      }*/
      let arr1 = []
      let arr2 = []
      checked.filter((items)=>{
        dataList.value.filter((item)=>{
          if(item.id === items){
            arr1.push(item)
          }
        })
      })
      let idArr = arr1.map((item)=>{
        return item.id
      })
      checked.filter((items)=>{
        if(idArr.indexOf(items)===-1){
          searchData.value.filter((item)=>{
            if(item.id === items){
              arr2.push(item)
            }
          })
        }
      })
      dataList.value = [].concat([...arr1,...arr2])
    }
    const treeToList = (arrs,childs,attrArr) => {
      let attrList = [];
      if(!Array.isArray(arrs)&&!arrs.length)return [];
      if(typeof childs !== 'string')return [];
      if(!Array.isArray(attrArr)||Array.isArray(attrArr)&&!attrArr.length){
        attrList = Object.keys(arrs[0]);
        attrList.splice(attrList.indexOf(childs), 1);
      }else{
        attrList = attrArr;
      }
      let list = [];
      const getObj = (arr)=>{
        arr.forEach(function(row){
          let obj = {};
          attrList.forEach(item=>{
            obj[item] = row[item];
          });
          //只添加末节点孩子
          if(!row[childs]){
            list.push(obj);
          }
          if(row[childs]){
            getObj(row[childs]);
          }
        })
        return list;
      }
      return getObj(arrs);
    }
    const handleDel = (record) => {
      searchDataList.value.filter((item,index)=>{
        if(item.id===record.id){
          searchDataList.value.splice(index,1)
        }
      })
      dataList.value.filter((item,index)=>{
        if(item.id===record.id){
          dataList.value.splice(index,1)
        }
      })
      let arr = dataList.value.map((item)=>{
        return item.id
      })
      checkedKeys.value = arr
    }
    const position = {
      start: undefined,
      end: undefined,
      sourceEl: undefined,
    }
    // 排序
    const reorder = ({ start, end }) => {
      if (start !== undefined && end !== undefined) {
        if (start > end) {
          // 当开始大于结束
          let temp = dataList.value[start]
          dataList.value.splice(start, 1);
          dataList.value.splice(end, 0, temp)
        } else if (start < end) {
          // 结束大于开始
          let temp = dataList.value[start]
          dataList.value.splice(start, 1)
          dataList.value.splice(end, 0, temp)
        }
      }
    }
    function rowKey(record){
      return record.id
    }
    function customRow(_record, index) {
      return {
        style: {
          cursor: "move",
        },
        // 鼠标移入
        onMouseenter: (event) => {
          // 兼容IE
          let ev = event || window.event;
          ev.target.draggable = true;
        },
        // 开始拖拽
        onDragstart: (event) => {
          // 兼容IE
          let ev = event || window.event;
          // 阻止冒泡
          ev.stopPropagation();
          // 得到源目标数据;
          position.start = index
          const tr = ev.target
          position.sourceEl = tr
        },
        // 拖动元素经过的元素
        onDragover: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
        },
        // 松开
        onDrop: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
          position.end = index
          reorder(position);
          animation(position)
        },
      };
    }
    // 实现动画效果
    function animation({ start, end, sourceEl }) {
      // 48 是每行的高度，也可以自动获取，根据情况而定
      let count = 48 * (start - end)
      sourceEl.style.translate = `0px ${count}px`
      setTimeout(() => {
        sourceEl.style.transition = "all 0.5s"
        sourceEl.style.translate = `0px 0px`
      })
    }
    watch(
        () => [props.visible,props.checkList],
        (val) => {
          if (val[0]) {
            leftValue.value = ''
            rightValue.value = ''
            treeData.value = []
            dataList.value = []
            getMenus()
          }
        },
        {immediate: true}
    );
    onMounted(()=>{
    })
    return {
      menuList,
      menuTreeToList,
      renderTreeNodes,
      leftValue,
      leftNum,
      rightValue,
      treeData,
      searchData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      columns,
      defaultList,
      searchDataList,
      dataList,
      checkTree,
      reset,
      closeModal,
      saveModal,
      clearLeft,
      onSearchLeft,
      treeToList,
      clearRight,
      onSearchRight,
      handleDel,
      rowKey,
      customRow
    };
  },
});
</script>

<style scoped lang="less">
.box-main{
  display: flex;
  font-size: 16px;
  color: #4d4d4d;
  .box-item{
    flex: 1;
    &.box-left{
      padding-right: 12px;
      border-right: 1px solid #EBEBEB;
    }
    &.box-right{
      padding-left: 12px;
    }
    .tt{
      display: flex;
      align-items: center;
      line-height: 38px;
      font-weight: 500;
      .num{
        margin-left: 12px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 14px;
        color: #999;
        background: #F6F6F6;
        border-radius: 4px;
        &.back-color{
          color: #3182CE;
          background: #E9F6FE;
        }
      }
    }
    .box-margin{
      margin-top: 8px;
    }
    .tree-title{
      padding-left: 30px;
      height: 48px;
      line-height: 48px;
      color: rgba(77, 77, 77, 0.6);
      background: #fafafa;
    }
    .tree-box{
      height: 288px;
      overflow: auto;
    }
  }
}
.btn-left{
  float: left;
  span{
    margin-left: 8px;
  }
}
.gray{
  color: #999 !important;
  border-color: #999 !important;
}
:deep(.ant-table-tbody > tr > td){
  border: 0;
}

:deep(.ant-table-thead > tr:first-child > th:first-child ) {
  padding-left: 30px;
}
:deep(.ant-table .ant-table-tbody > tr > td:first-child ) {
  padding-left: 30px;
}
:deep(.ant-table-row) {
  background: url("../../../../assets/iconfont/drap.png") 5px no-repeat;
  background-size: 20px 20px;
}
:deep(.ant-tree){
  font-size: 16px;
}
:deep(.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop){
  display: none;
}
.font-blue{
  color: #3182CE;
}
.icon-btn{
  cursor: pointer;
}
</style>
