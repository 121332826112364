<template>
  <section class="dashboard" ref="dashboardRef">
    <div class="info">
      <a-row class="a-row" :gutter="16">
        <a-col :span="8">
          <PensonInfo class="box-shadow" />
        </a-col>
        <a-col :span="6">
          <TodoInfo ref="todoInfoRef" class="box-shadow" />
        </a-col>
        <a-col :span="10">
          <QuickEntry class="box-shadow" />
        </a-col>
      </a-row>
    </div>
    <div class="content">
      <a-row class="a-row" :gutter="16">
        <a-col :span="8">
          <Demand ref="demandRef" class="box-shadow" :orgFourOneOne="state.orgFourOneOne" />
        </a-col>
        <a-col :span="9">
          <TransferOrder ref="transferOrderRef" class="box-shadow" :orgFourOneOne="state.orgFourOneOne" />
        </a-col>
        <a-col :span="7">
          <WelcomeLetter ref="welcomeLetterRef" class="box-shadow" :orgFourOneOne="state.orgFourOneOne" />
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref, watch,
} from "vue";
import { useRouter } from "vue-router";
import PensonInfo from '@/views/home/Dashboard/component/PensonInfo'
import TodoInfo from '@/views/home/Dashboard/component/TodoInfo'
import QuickEntry from '@/views/home/Dashboard/component/QuickEntry'
import Demand from '@/views/home/Dashboard/component/Demand'
import TransferOrder from '@/views/home/Dashboard/component/TransferOrder'
import WelcomeLetter from '@/views/home/Dashboard/component/WelcomeLetter'
import {useGlobalPropertyHook} from '@/hooks/common'

export default defineComponent({
  name: "Dashboard",
  components: {PensonInfo,TodoInfo,QuickEntry,Demand,TransferOrder,WelcomeLetter},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const state = reactive({
      orgFourOneOne: []
    })
    const todoInfoRef = ref(null)
    const demandRef = ref(null)
    const transferOrderRef = ref(null)
    const welcomeLetterRef = ref(null)
    //查询org3.2/org4.2
    const getOrgList = () => {
      $api
          .getOrgThree_FourList()
          .then((res) => {
            const {orgFourOneOne} = res.data;
            state.orgFourOneOne = orgFourOneOne;
          });
    };
    let refreshTimer

    /**
     * 使用watch监控当前 router 的变化
     */
    const useRouterCurrent = reactive(useRouter());
    watch(
        useRouterCurrent,
        o => {
          if(o.currentRoute.name === "Dashboard"){
            setTimeout(()=>{
              todoInfoRef?.value.refresh()
              demandRef?.value.refresh()
              transferOrderRef?.value.refresh()
              welcomeLetterRef?.value.refresh()
            })
            refreshTimer = setInterval(() => {
              todoInfoRef?.value.refresh()
              demandRef?.value.refresh()
              transferOrderRef?.value.refresh()
              welcomeLetterRef?.value.refresh()
            }, 1000 * 60 * 30);
          }else{
            clearInterval(refreshTimer)
          }
        },
        {
          immediate: true
        }
    );
    onMounted(()=>{
      getOrgList()
    })
    return {
      state,
      refreshTimer,
      todoInfoRef,
      demandRef,
      transferOrderRef,
      welcomeLetterRef
    };
  },
});
</script>
<style scoped lang="less">
.dashboard {
  padding: 14px 24px;
  width: 100%;
  height: 100%;
  background: #fff;
  .info{
    width: 100%;
    height: 144px;
  }
  .content{
    margin-top: 16px;
    width: 100%;
    height: calc(100% - 156px);
  }
  .a-row{
    height: 100%;
    :deep(.ant-col){
      height: 100%;
    }
    .box-shadow{
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: rgba(0, 0, 0, 0.08) 0 0 10px;
    }
  }
}
:deep(.no-data-box){
  padding-top: 94px;
  .no-data-img{
    width: 100%;
    height: 267px;
    background: url("../../../assets/images/no_data.png") 50% 0 no-repeat;
    background-size: 400px 267px;
  }
  .no-data-info{
    margin-top: 10px;
    line-height: 22px;
    text-align: center;
    font-size: 16px;
  }
}
</style>