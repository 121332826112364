<template>
  <section class="transfer-order" ref="transferOrderRef">
    <div class="transfer-order-top">
      <span class="title">调令管理</span>
      <SelectWithAll
          class="position-r"
          v-model:value="selValueArr"
          placeholder="请选择Offering"
          mode="multiple"
          showArrow
          allowClear
          :maxTagCount="1"
      >
        <a-select-option
            v-for="(item, index) in orgFourOneOne"
            :key="index"
            :value="item"
        ><span :title="item">{{ item }}</span>
        </a-select-option>
      </SelectWithAll>
    </div>
    <div class="transfer-order-main">
      <div class="echarts-box">
        <PieChart class="pie-chart" :chartIndex="1" :data="chartData" @chartClick="goTodoListQuery" />
        <div class="info">
          <ul>
            <li>
              <Tooltip :title="'距顾问上/下项目'">
                <span>1天内</span>
              </Tooltip>
              <span class="font-black border-red" @click="goTodoListQueryStatus(1)">{{ state.assignment[0]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距顾问上/下项目'">
                <span>2~5天</span>
              </Tooltip>
              <span class="font-black border-yellow" @click="goTodoListQueryStatus(2)">{{ state.assignment[1]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距顾问上/下项目'">
                <span>5天以上</span>
              </Tooltip>
              <span class="font-black border-green" @click="goTodoListQueryStatus(3)">{{ state.assignment[2]?.title }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="data-list">
        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div class="item" :class="item.color">
              <div class="item-top">
                <div class="tt" @click="goTodoList(item)">{{ item.fullName }}</div>
                <div class="code">
                  <span>Engagement Code: </span>
                  <span>{{ item.engagementCode }}</span>
                </div>
              </div>
              <div  class="item-bottom">
                <div class="info">
                  <div class="info-item border-right-blue">
                    <Tooltip title="Team">
                      <TeamOutlined :style="{display:'inline-block'}"/>
                    </Tooltip>
                    <span class="margin">{{ item.orgThreeTwo }}</span>
                  </div>
                  <div class="info-item">
                    <Tooltip title="职级">
                      <i class="icon-rank"></i>
                    </Tooltip>
                    <span class="margin">{{ item.grade }}</span>
                  </div>
                </div>
                <div class="term">
                  <span>Assignment Term: </span>
                  <span>{{ item.onBoardDate }} ~ {{ item.estimateReleaseDate }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="noData" class="no-data-box">
          <div class="no-data-img"></div>
          <div class="no-data-info">暂无待办事项</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted, reactive,
  ref,
  //reactive,
  watch,
} from "vue";
import {
  TeamOutlined,
} from '@ant-design/icons-vue';
import {useGlobalPropertyHook} from '@/hooks/common'
import {useRouter} from 'vue-router'
import Tooltip from '@/components/Tooltip'
import { debounce } from "lodash";
import PieChart from './PieChart'
export default defineComponent({
  name: "TransferOrder",
  components: {Tooltip,PieChart,TeamOutlined},
  props: {
    orgFourOneOne: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const selValueArr = ref([])
    const chartData = ref([])
    const dataList = ref([])
    const state = reactive({
      assignment: []
    })
    const noData = ref(false)
    const getTransferOrderChart = () => {
      const chartParams = {
        Offering:selValueArr.value
      }
      $api.queryAssignmentsNum(chartParams).then((res) => {
        const {oneDayList,twoToFiveList,fiveOverList,assignment } = res.data
        let a = oneDayList.map((item)=>{
          return {
            ...item,
            color: 'border-red'
          }
        })
        let b = twoToFiveList.map((item)=>{
          return {
            ...item,
            color: 'border-yellow'
          }
        })
        let c = fiveOverList.map((item)=>{
          return {
            ...item,
            color: 'border-green'
          }
        })
        dataList.value = [...a,...b,...c]
        dataList.value.length>0?noData.value=false:noData.value=true
        state.assignment = [...assignment]
        chartData.value = assignment.map((item,index)=>{
          return {
            ...item,
            name: dict(index),
            value: item.title
          }
        })
      });
    };
    const dict = (data) => {
      switch (data){
        case 0:
          return '1天内'
        case 1:
          return '2~5天'
        case 2:
          return '5天以上'
        default :
          ''  // 如果没有与表达式相同的值，则执行该代码
      }
    }
    const goTodoListQuery = (data) => {
      sessionStorage.setItem('dashboardStatus',data.status)
      router.push({
        path: '/todoList',
        query: {
          flag: '2',
        }
      });
    }
    const goTodoListQueryStatus = (data) => {
      sessionStorage.setItem('dashboardStatus',data)
      router.push({
        path: '/todoList',
        query: {
          flag: '2',
        }
      });
    }
    const goTodoList = (data) => {
      sessionStorage.setItem("itemData",JSON.stringify(data))
      router.push({
        path: '/todoList',
        query: {
          flag: '2',
        }
      });
    }
    const refresh = ()=>{
      getTransferOrderChart()
    }
    onMounted(()=>{
      getTransferOrderChart()
    })
    watch(selValueArr, debounce(() => {
      getTransferOrderChart()
    },500));
    return {
      selValueArr,
      chartData,
      noData,
      dataList,
      state,
      dict,
      goTodoList,
      goTodoListQuery,
      goTodoListQueryStatus,
      refresh
    };
  },
});
</script>

<style scoped lang="less">
.transfer-order{
  padding: 12px 0;
  height: 100%;
  font-size: 16px;
  color: #999;
  .transfer-order-top{
    position: relative;
    padding: 0 24px;
    .title{
      font-size: 22px;
      font-weight: 600;
      color: #4d4d4d;
    }
    .position-r{
      position: absolute;
      top: 0;
      right: 24px;
      width: 200px;
      line-height: 36px;
    }
  }

}
.transfer-order-main{
  height: calc(100% - 35px);
  .echarts-box{
    display: flex;
    justify-content: center;
    .pie-chart{
      width: 100px;
      height: 100px;
    }
    .info{
      color: #999;
      font-size: 16px;
      ul{
        display: flex;
        margin: 0 0 0 30px;
        width: 220px;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        li{
          span{
            display: block;
            line-height: 22px;
          }
        }
      }
    }
  }
  .data-list{
    padding: 0 24px;
    height: calc(100% - 96px);
    overflow: auto;
    ul{
      margin: 0;
      li{
        padding: 8px 0;
        border-bottom: 1px solid #EBEBEB;
        .item{
          margin: 5px 0;
          padding-left: 12px;
          height: 48px;
          font-size: 16px;
          .item-top{
            position: relative;
            display: flex;
            align-items: center;
            .tt{
              display: inline-block;
              line-height: 25px;
              font-size: 18px;
              color: #4D4D4D;
              font-weight: 600;
              border-bottom: 1px solid #4D4D4D;
              cursor: pointer;
            }
            .code{
              position: absolute;
              top: 0;
              right: 0;
              padding: 0 8px;
              line-height: 25px;
              text-align: right;
              span:last-child{
                font-weight: 500;
                color: #4D4D4D;
              }
            }
          }
          .item-bottom{
            position: relative;
            .info{
              height: 22px;
              .info-item {
                float: left;
                display: flex;
                align-items: center;
                margin-right: 12px;
                line-height: 20px;
                &.border-right-blue {
                  border-right: 1px solid #77CAED;
                }
                :deep(.anticon) {
                  height: 100%;
                  color: #3182CE;
                  svg {
                    width: 16px;
                    height: 100%;
                  }
                }
                .margin {
                  padding: 0 12px 0 8px;
                }
                .icon-rank{
                  display: block;
                  width: 16px;
                  height: 16px;
                  background: url("../../../../assets/images/icon_rank.png") 100% no-repeat;
                  background-size: 100%;
                  cursor: pointer;
                }
              }
            }
            .term{
              position: absolute;
              top: 0;
              right: 0;
              padding: 0 8px;
              line-height: 22px;
              text-align: right;
              span:last-child{
                font-weight: 500;
                color: #4D4D4D;
              }
            }
          }
        }
      }
    }
  }
}
.font-black{
  margin: 4px 0;
  padding-left: 12px;
  line-height: 20px;
  color: #4d4d4d;
  cursor: pointer;
}
.border-red{
  border-left: 4px solid #DC5E4F;
}
.border-yellow{
  border-left: 4px solid #FFB18F;
}
.border-green{
  border-left: 4px solid #7CCAB4;
}
:deep(.ant-select-selection-overflow-item){
  max-width: 100px;
}
</style>
