<template>
  <section class="demand" ref="demandRef">
    <div class="demand-top">
      <span class="title">需求匹配</span>
      <SelectWithAll
          class="position-r"
          v-model:value="selValueArr"
          placeholder="请选择Offering"
          mode="multiple"
          showArrow
          allowClear
          :maxTagCount="1"
      >
        <a-select-option
            v-for="(item, index) in orgFourOneOne"
            :key="index"
            :value="item"
        ><span :title="item">{{ item }}</span>
        </a-select-option>
      </SelectWithAll>
    </div>
    <div class="demand-main">
      <div class="echarts-box">
        <PieChart class="pie-chart" :chartIndex="0" :data="chartData" @chartClick="goTodoListQuery" />
        <div class="info">
          <ul>
            <li>
              <Tooltip :title="'距需求匹配进入待办列表'">
                <span>5天以上</span>
              </Tooltip>
              <span class="font-black border-red" @click="goTodoListQueryStatus(1)">{{ state.preMatching[0]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距需求匹配进入待办列表'">
                <span>2~5天</span>
              </Tooltip>
              <span class="font-black border-yellow" @click="goTodoListQueryStatus(2)">{{ state.preMatching[1]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距需求匹配进入待办列表'">
                <span>1天内</span>
              </Tooltip>
              <span class="font-black border-green" @click="goTodoListQueryStatus(3)">{{ state.preMatching[2]?.title }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="data-list">
        <ul v-if="!noData">
          <li v-for="(item,index) in dataList" :key="index">
            <div class="item" :class="item.color">
              <div class="item-top">
                <span class="num">{{ item.demandNumber }}</span>
                <Tooltip :title="item.demandName">
                  <span class="tt" @click="goTodoList(item)">{{ item.demandName }}</span>
                </Tooltip>
                <span class="status">{{ item.preStatus }}</span>
              </div>
              <div  class="item-bottom">
                <span>{{ item.fullName }}<i class="point"></i>{{ item.workLocation }}<i v-if="item.completeChannel" class="point"></i>{{ item.completeChannel }}</span>
                <span class="status-progress">
                  <i class="icon-status" v-for="i in 4" :key="i"></i>
                  <i class="icon-status icon-status-gray"></i>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="noData" class="no-data-box">
          <div class="no-data-img"></div>
          <div class="no-data-info">暂无待办事项</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted, reactive,
  ref,
  //reactive,
  watch,
} from "vue";
import {useGlobalPropertyHook} from '@/hooks/common'
import {useRouter} from "vue-router"
import Tooltip from '@/components/Tooltip'
import { debounce } from "lodash";
import PieChart from './PieChart'
export default defineComponent({
  name: "Demand",
  components: {Tooltip,PieChart},
  props: {
    orgFourOneOne: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const selValueArr = ref([])
    const chartData = ref([])
    const dataList = ref([])
    const state = reactive({
      preMatching: []
    })
    const noData = ref(false)
    const getDemandChart = () => {
      chartData.value = []
      dataList.value = []
      const chartParams = {
        Offering:selValueArr.value
      }
      $api.queryPrematchingsNum(chartParams).then((res) => {
        const {fiveOverList,twoToFiveList,oneDayList,preMatching } = res.data
        let a = fiveOverList.map((item)=>{
          return {
            ...item,
            color: 'border-red'
          }
        })
        let b = twoToFiveList.map((item)=>{
          return {
            ...item,
            color: 'border-yellow'
          }
        })
        let c = oneDayList.map((item)=>{
          return {
            ...item,
            color: 'border-green'
          }
        })
        dataList.value = [...a,...b,...c]
        dataList.value.length>0?noData.value=false:noData.value=true
        state.preMatching = [...preMatching]
        chartData.value = preMatching.map((item,index)=>{
          return {
            ...item,
            name: dict(index),
            value: item.title
          }
        })
      });
    };
    const dict = (data) => {
      switch (data){
        case 0:
          return '5天以上'
        case 1:
          return '2~5天'
        case 2:
          return '1天内'
        default :
          ''  // 如果没有与表达式相同的值，则执行该代码
      }
    }
    const goTodoListQuery = (data) => {
      sessionStorage.setItem('dashboardStatus',data.status)
      router.push({
        path: '/todoList',
        query: {
          flag: '1',
        }
      });
    }
    const goTodoListQueryStatus = (data) => {
      sessionStorage.setItem('dashboardStatus',data)
      router.push({
        path: '/todoList',
        query: {
          flag: '1',
        }
      });
    }
    const goTodoList = (data) => {
      sessionStorage.setItem("itemData",JSON.stringify(data))
      router.push({
        path: '/todoList',
        query: {
          flag: '1',
        }
      });
    }
    const refresh = () => {
      getDemandChart()
    }
    onMounted(()=>{
      getDemandChart()
    })
    watch(selValueArr, debounce(() => {
      getDemandChart()
    },500));
    return {
      selValueArr,
      chartData,
      noData,
      dataList,
      state,
      dict,
      goTodoList,
      goTodoListQuery,
      goTodoListQueryStatus,
      refresh
    };
  },
});
</script>

<style scoped lang="less">
.demand{
  padding: 12px 0;
  height: 100%;
  font-size: 16px;
  color: #999;
  .demand-top{
    position: relative;
    padding: 0 24px;
    .title{
      font-size: 22px;
      font-weight: 600;
      color: #4d4d4d;
    }
    .position-r{
      position: absolute;
      top: 0;
      right: 24px;
      width: 200px;
      line-height: 36px;
    }
  }

}
.demand-main{
  height: calc(100% - 35px);
  .echarts-box{
    display: flex;
    justify-content: center;
    .pie-chart{
      width: 100px;
      height: 100px;
    }
    .info{
      color: #999;
      font-size: 16px;
      ul{
        display: flex;
        margin: 0 0 0 30px;
        width: 220px;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        li{
          span{
            display: block;
            line-height: 22px;
          }
        }
      }
    }
  }
  .data-list{
    padding: 0 24px;
    height: calc(100% - 96px);
    overflow: auto;
    ul{
      margin: 0;
      li{
        padding: 8px 0;
        border-bottom: 1px solid #EBEBEB;
        .item{
          margin: 5px 0;
          padding-left: 12px;
          height: 48px;
          font-size: 16px;
          .item-top{
            position: relative;
            display: flex;
            align-items: center;
            .num{
              display: inline-block;
              padding: 0 4px;
              line-height: 20px;
              color: #3182CE;
              font-weight: 600;
              border: 1px solid #77CAED;
              border-radius: 4px;
            }
            .tt{
              display: inline-block;
              margin-left: 8px;
              max-width: 320px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 25px;
              font-size: 18px;
              color: #4D4D4D;
              font-weight: 600;
              border-bottom: 1px solid #4D4D4D;
              cursor: pointer;
            }
            .status{
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -15px;
              padding: 0 8px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: #77CAED;
              background: #E9F6FE;
              border-radius: 15px;
            }
          }
          .item-bottom{
            position: relative;
            .point{
              position: relative;
              display: inline-block;
              margin: 0 8px;
              width: 4px;
              height: 12px;
              &:before{
                position: absolute;
                display: block;
                content: '';
                top: 4px;
                width: 4px;
                height: 4px;
                background: #999;
                border-radius: 2px;
              }
            }
            .status-progress{
              position: absolute;
              top: 50%;
              right: 0;
              display: flex;
              margin-top: -3px;
              gap: 8px;
              .icon-status{
                display: inline-block;
                width: 28px;
                height: 6px;
                background: #1890FF;
                border-radius: 3px;
              }
              .icon-status-gray{
                background: #EBEBEB;
              }
            }
          }
        }
      }
    }
  }
}
.font-black{
  margin: 4px 0;
  padding-left: 12px;
  line-height: 20px;
  color: #4d4d4d;
  cursor: pointer;
}
.border-red{
  border-left: 4px solid #DC5E4F;
}
.border-yellow{
  border-left: 4px solid #FFB18F;
}
.border-green{
  border-left: 4px solid #7CCAB4;
}
:deep(.ant-select-selection-overflow-item){
  max-width: 100px;
}
</style>
