<template>
  <section class="todo-info" ref="todoInfoRef">
    <div class="info-top">
      <span class="title">快捷入口</span>
      <Tooltip :title="'快捷入口支持自定义功能'" :placement="'rightBottom'">
        <QuestionCircleOutlined class="icon-tip" />
      </Tooltip>
      <Tooltip :title="'设置'" v-auth="['system:manual:preview']">
        <SettingOutlined class="icon-setting" @click="handleSetting" />
      </Tooltip>
    </div>
    <div class="info-main">
      <ul>

        <li v-for="(item, index) in dataList" :key="index">
          <span class="icon-entry" @click="handleEntry(item)">
            <i class="icon-size" :class="item.icon" />
          </span>
          <span class="tt">{{item.secondMenu?item.secondMenu:item.firstMenu}}</span>
        </li>
        <template v-if="dataList.length<5">
          <li v-for="index in (5-dataList.length)" :key="index" @click="handleSetting">
            <Tooltip :title="'添加'">
            <span class="icon-entry">
              <PlusOutlined class="icon-size" />
            </span>
            </Tooltip>
          </li>
        </template>
      </ul>
    </div>
    <CustomEntry v-model:visible="visible" :checkList="checkList" @closeModal="closeModal" @updateList="getQuickEntry('1')" @resetList="getQuickEntry('0')" />
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
} from "vue";
import Tooltip from '@/components/Tooltip'
import {QuestionCircleOutlined,SettingOutlined,PlusOutlined} from '@ant-design/icons-vue'
import CustomEntry from '@/views/home/Dashboard/component/CustomEntry'
import {useGlobalPropertyHook} from '@/hooks/common'
import {useRouter} from "vue-router";

export default defineComponent({
  name: "QuickEntry",
  components: {CustomEntry, Tooltip,QuestionCircleOutlined,SettingOutlined,PlusOutlined},
  setup() {
    const router = useRouter();
    const visible = ref(false)
    const { $api } = useGlobalPropertyHook();
    const dataList = ref([])
    const checkList = ref([])
    const getQuickEntry = (flag) => {
      $api.getQueryQuickEntry(flag).then((res) => {
        if(flag==='1'){
          dataList.value = res.data.sort(sortBy('sort')).map((item)=>{
            return {
              ...item,
              icon: iconDict(item.firstMenuId)
            }
          })
        }
        checkList.value = [].concat([...res.data.sort(sortBy('sort'))])
      });
    };
    const iconDict = (data)=>{
      switch (data){
        case '0':
          return 'icon-fast-1'
        case '28':
          return 'icon-fast-4'
        case '15a49405-f510-4aa3-83ed-ae491553139c':
          return 'icon-fast-5'
        case '5':
          return 'icon-fast-7'
        case '227a664e-3e2e-4bcd-ac1f-6623affa8af1':
          return 'icon-fast-2'
        case '34a49405-f510-4aa3-83ed-ae491553139c':
          return 'icon-fast-8'
        default :
          ''  // 如果没有与表达式相同的值，则执行该代码
      }
    }
    const sortBy = (field) => {
      //根据传过来的字段进行排序
      return (x, y) => {
        return x[field] - y[field]
      }
    }
    const handleSetting = () => {
      visible.value=true
    }
    const handleEntry = (data) => {
      router.push({
        path: data.secondMenuPath,
      });
    }
    const closeModal = (flag) => {
      visible.value=false
      //不保存 初始化个人设置快捷入口列表
      flag?'':getQuickEntry('1')
    }
    onMounted(()=>{
      getQuickEntry('1')
    })
    return {
      handleSetting,
      handleEntry,
      closeModal,
      visible,
      getQuickEntry,
      dataList,
      checkList,
      iconDict
    };
  },
});
</script>

<style scoped lang="less">
.todo-info{
  padding: 12px 24px;
  height: 100%;
  font-size: 16px;
  color: #999;
  .info-top{
    position: relative;
    display: flex;
    align-items: center;
    .title{
      font-size: 22px;
      font-weight: 600;
      color: #4d4d4d;
    }
    .icon-tip{
      margin-left: 8px;
      color: #3182CE;
    }
    .icon-setting{
      position: absolute;
      right: 0;
      width: 18px;
      height: 18px;
      :deep(svg){
        width: 100%;
        height: 100%;
      }
    }
  }
}
.info-main{
  margin-top: 4px;
  ul{
    display: flex;
    width: 100%;
    justify-content: space-between;
    li{
      width: 120px;
      height: 94px;
      span{
        display: block;
        text-align: center;
      }
      .icon-entry{
        display: flex;
        margin: 0 auto;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        border: 1px solid #77CAED;
        border-radius: 8px;
        cursor: pointer;
        .icon-size{
          width: 30px;
          height: 30px;
          color: #3182CE;
          :deep(svg){
            width: 100%;
            height: 100%;
          }
        }
        .icon-fast-1{
          background: url("../../../../assets/images/icon_fast_1.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-2{
          background: url("../../../../assets/images/icon_fast_2.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-3{
          background: url("../../../../assets/images/icon_fast_3.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-4{
          background: url("../../../../assets/images/icon_fast_4.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-5{
          background: url("../../../../assets/images/icon_fast_5.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-6{
          background: url("../../../../assets/images/icon_fast_6.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-7{
          background: url("../../../../assets/images/icon_fast_7.png") no-repeat;
          background-size: 30px 30px;
        }
        .icon-fast-8{
          background: url("../../../../assets/images/icon_fast_8.png") no-repeat;
          background-size: 30px 30px;
        }
      }
      .tt{
        margin-top: 4px;
      }
    }
  }
}
</style>
