<template>
  <section class="person-info" ref="personInfoRef">
    <div class="info-top">
      <span class="name">{{ state.userData.fullName }}</span>
      <span class="position">{{ state.userData.roleName }}</span>
    </div>
    <div class="info-main">
      <div class="item border-right-blue">
        <Tooltip title="GGID">
          <IdcardOutlined :style="{display:'inline-block'}"/>
        </Tooltip>
        <span class="margin">{{ state.userData.ggId }}</span>
      </div>
      <div class="item border-right-blue">
        <Tooltip title="职级">
          <i class="icon-rank"></i>
        </Tooltip>
        <span class="margin">{{ state.userData.psGroup }}</span>
      </div>
      <div class="item">
        <Tooltip title="Team">
          <TeamOutlined :style="{display:'inline-block'}"/>
        </Tooltip>
        <span class="margin">{{ state.userData.orgThreeTwo }}</span>
      </div>
      <div class="item border-right-blue clear">
        <Tooltip title="邮箱">
          <MailOutlined :style="{display:'inline-block'}"/>
        </Tooltip>
        <span class="margin">{{ state.userData.email }}</span></div>
      <div class="item">
        <Tooltip title="Location">
          <EnvironmentOutlined :style="{display:'inline-block'}"/>
        </Tooltip>
        <span class="margin">{{ state.userData.lmWorkLocation }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import {
  IdcardOutlined,
  TeamOutlined,
  MailOutlined,
  EnvironmentOutlined
} from '@ant-design/icons-vue';
import Tooltip from '@/components/Tooltip'
import {useGlobalPropertyHook} from '@/hooks/common'
export default defineComponent({
  name: "PersonInfo",
  components: {Tooltip, IdcardOutlined,TeamOutlined,MailOutlined,EnvironmentOutlined},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const state = reactive({
      userData: {}
    })
    const position = localStorage.getItem("switchRoleName")
    const getUserData = () => {
      $api.myUserInfo().then((res) => {
        state.userData = res.data;
      });
    };
    const init = () => {
      getUserData();
    };
    onMounted(()=>{
      init();
    })
    return {
      state,
      position
    };
  },
});
</script>

<style scoped lang="less">
.person-info{
  padding: 12px 24px;
  height: 100%;
  font-size: 16px;
  color: #999;
  .info-top{
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    .name{
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;
      color: #4d4d4d;
    }
    .position{
      margin-left: 16px;
      padding: 2px 16px;
      height: 26px;
      line-height: 22px;
      color: #12ABDB;
      background: #E9F6FE;
      border-radius: 13px;
    }
  }
  .info-main{
    overflow: auto;
    .item{
      float: left;
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-bottom: 8px;
      line-height: 22px;
      &.border-right-blue{
        border-right: 1px solid #77CAED;
      }
      :deep(.anticon){
        height: 100%;
        color: #3182CE;
        svg{
          width: 20px;
          height: 100%;
        }
      }
      .margin{
        padding: 0 16px 0 8px;
      }
      .icon-rank{
        display: block;
        width: 20px;
        height: 22px;
        background: url("../../../../assets/images/icon_rank.png") 100% no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
    }
    .clear{
      clear: both;
    }
  }
}
.font-color{
  color:#4d4d4d;
}
</style>
