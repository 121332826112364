<template>
  <div class="chart" ref="chart" v-resize="adapterScreen"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "PieChart",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    chartIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fontPie: null
    }
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.chart);
      this.chart.on('click',(params)=>{
        this.$emit('chartClick', params.data)
      })
    },
    setOption() {
      // console.log(this.config, "配置项");
      this.chart.setOption({
        tooltip: {
          position: function (point) {
            return [point[0]+15, point[1]-40];
          },
          transitionDuration: 0.2,
          //showDelay: 1000,
          formatter: (params) => {
            let str =''
            this.chartIndex ===0?
                str=`紧急度：${params.data.name}<br>数量: ${params.data.value}<br>占比: ${params.data.proportion}`:
                str=`紧急度：${params.data.name}<br>人数: ${params.data.value}<br>占比: ${params.data.proportion}`
            return str
          },
          textStyle: {
            fontSize: 10
          }
        },
        color: ['#DC5E4F','#FFB18F','#7CCAB4'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '80%'],
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      });
    },
    adapterScreen() {
      //this.chart = echarts.init(this.$refs.chart);
      this.fontPie = (this.$refs.chart.offsetWidth / 100) * 2.7
      const adpOption = {
        tooltip: {
          position: function (point) {
            return [point[0]+15, point[1]-40];
          },
          transitionDuration: 0.2,
          //showDelay: 1000,
          formatter: (params) => {
            let str =''
            this.chartIndex ===0?
                str=`紧急度：${params.data.name}<br>数量: ${params.data.value}<br>占比: ${params.data.proportion}`:
                str=`紧急度：${params.data.name}<br>人数: ${params.data.value}<br>占比: ${params.data.proportion}`
            return str
          },
          textStyle: {
            fontSize: 10
          }
        },
        color: ['#DC5E4F','#FFB18F','#7CCAB4'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '80%'],
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      }
      this.chart.setOption(adpOption);
      this.chart.resize();
    },
  },
  watch:{
    data: {
      handler() {
        this.adapterScreen()
      },
    }
  },
  mounted() {
    this.init();
    this.setOption();
    this.adapterScreen();
  },
};
</script>
<style scoped lang="less">
.chart{
  width: 100px;
  height: 100px;
}
</style>