<template>
  <section class="welcome-letter" ref="welcomeLetterRef">
    <div class="welcome-letter-top">
      <span class="title">Welcome Letter</span>
      <SelectWithAll
          class="position-r"
          v-model:value="selValueArr"
          placeholder="请选择Offering"
          mode="multiple"
          showArrow
          allowClear
          :maxTagCount="1"
      >
        <a-select-option
            v-for="(item, index) in orgFourOneOne"
            :key="index"
            :value="item"
        ><span :title="item">{{ item }}</span>
        </a-select-option>
      </SelectWithAll>
    </div>
    <div class="welcome-letter-main">
      <div class="echarts-box">
        <PieChart class="pie-chart" :chartIndex="2" :data="chartData" @chartClick="goTodoListQuery" />
        <div class="info">
          <ul>
            <li>
              <span>已入职</span>
              <span class="font-black border-red" @click="goTodoListQueryStatus(1)">{{ state.welcomeLetter[0]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距顾问入职还有'">
                <span>1~3天</span>
              </Tooltip>
              <span class="font-black border-yellow" @click="goTodoListQueryStatus(2)">{{ state.welcomeLetter[1]?.title }}</span>
            </li>
            <li>
              <Tooltip :title="'距顾问入职还有'">
                <span>3天以上 </span>
              </Tooltip>
              <span class="font-black border-green" @click="goTodoListQueryStatus(3)">{{ state.welcomeLetter[2]?.title }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="data-list">
        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div class="item" :class="item.color">
              <div class="item-left">
                <span class="name" @click="goTodoList(item)">{{ item.fullName }}</span>
                <span class="team">{{ item.orgThreeTwo }}</span>
              </div>
              <div  class="item-right">
                <div class="date-box" :class="item.sendmailDate?'box-blue':''">
                  <span>{{ dateFormat(item.sendmailDate) }}</span>
                  <span>发送时间</span>
                </div>
                <div class="date-box" :class="dateColor(item.onboardDate)">
                  <span>{{ dateFormat(item.onboardDate,true) }}</span>
                  <span>入职日期</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="noData" class="no-data-box">
          <div class="no-data-img"></div>
          <div class="no-data-info">暂无待办事项</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted, reactive,
  ref,
  watch,
} from "vue";
import {useGlobalPropertyHook} from '@/hooks/common'
import {useRouter} from "vue-router"
import Tooltip from '@/components/Tooltip'
import { debounce } from "lodash";
import PieChart from './PieChart'
import moment from "moment";
export default defineComponent({
  name: "WelcomLetter",
  components: {Tooltip,PieChart},
  props: {
    orgFourOneOne: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const selValueArr = ref([])
    const chartData = ref([])
    const dataList = ref([])
    const state = reactive({
      welcomeLetter: []
    })
    const noData = ref(false)
    const getWelcomeLetterChart = () => {
      const chartParams = {
        Offering:selValueArr.value
      }
      $api.queryWelcomeLetterNum(chartParams).then((res) => {
        const {employedList,oneToThreeList,overThreeList,welcomeLetter } = res.data
        let a = employedList.map((item)=>{
          return {
            ...item,
            color: 'border-red'
          }
        })
        let b = oneToThreeList.map((item)=>{
          return {
            ...item,
            color: 'border-yellow'
          }
        })
        let c = overThreeList.map((item)=>{
          return {
            ...item,
            color: 'border-green'
          }
        })
        dataList.value = [...a,...b,...c]
        dataList.value.length>0?noData.value=false:noData.value=true
        state.welcomeLetter = [...welcomeLetter]
        chartData.value = welcomeLetter.map((item,index)=>{
          return {
            ...item,
            name: dict(index),
            value: item.title
          }
        })
      });
    };
    const dict = (data) => {
      switch (data){
        case 0:
          return '已入职'
        case 1:
          return '1~3天'
        case 2:
          return '3天以上'
        default :
          ''  // 如果没有与表达式相同的值，则执行该代码
      }
    }
    const goTodoListQuery = (data) => {
      sessionStorage.setItem('dashboardStatus',data.status)
      router.push({
        path: '/todoList',
        query: {
          flag: '3',
        }
      });
    }
    const goTodoListQueryStatus = (data) => {
      sessionStorage.setItem('dashboardStatus',data)
      router.push({
        path: '/todoList',
        query: {
          flag: '3',
        }
      });
    }
    const goTodoList = (data) => {
      sessionStorage.setItem("itemData",JSON.stringify(data))
      router.push({
        path: '/todoList',
        query: {
          flag: '3',
        }
      });
    }
    const dateFormat=(date,flag)=>{
      let str = ''
      if(date){
        if(flag){
          if(moment(date).isSame(moment(), "day")){
            str = '今天'
          }else if(moment(date).isSame(moment().subtract(1,'day'), "day")){
            str = '昨天'
          }else if(moment(date).isSame(moment().subtract(-1,'day'), "day")){
            str = '明天'
          }else {
            str = moment(date).format('MM/DD')
          }
        }else{
          str = moment(date).format('MM/DD')
        }
      }else{
        str = '未设置'
      }
      return str
    }
    const dateColor=(date)=>{
      let str = ''
      if(moment(date).diff(moment(), 'days') < 0||moment(date).isSame(moment(), "day")){
        str = 'box-red'
      }else if(moment(date).diff(moment(), 'days') > 2){
        str = 'box-green'
      }else {
        str = 'box-yellow'
      }
      return str
    }
    const refresh = () =>{
      getWelcomeLetterChart()
    }
    onMounted(()=>{
      getWelcomeLetterChart()
    })
    watch(selValueArr, debounce(() => {
      getWelcomeLetterChart()
    },500));
    return {
      selValueArr,
      chartData,
      noData,
      dataList,
      state,
      dict,
      goTodoList,
      goTodoListQuery,
      goTodoListQueryStatus,
      dateFormat,
      dateColor,
      refresh
    };
  },
});
</script>

<style scoped lang="less">
.welcome-letter{
  padding: 12px 0;
  height: 100%;
  font-size: 16px;
  color: #999;
  .welcome-letter-top{
    position: relative;
    padding: 0 24px;
    .title{
      font-size: 22px;
      font-weight: 600;
      color: #4d4d4d;
    }
    .position-r{
      position: absolute;
      top: 0;
      right: 24px;
      width: 200px;
      line-height: 36px;
    }
  }

}
.welcome-letter-main{
  height: calc(100% - 35px);
  .echarts-box{
    display: flex;
    justify-content: center;
    .pie-chart{
      width: 100px;
      height: 100px;
    }
    .info{
      color: #999;
      font-size: 16px;
      ul{
        display: flex;
        margin: 0 0 0 30px;
        width: 220px;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        li{
          span{
            display: block;
            line-height: 22px;
          }
        }
      }
    }
  }
  .data-list{
    padding: 0 24px;
    height: calc(100% - 96px);
    overflow: auto;
    ul{
      margin: 0;
      li{
        position: relative;
        padding: 8px 0;
        border-bottom: 1px solid #EBEBEB;
        .item{
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          padding-left: 12px;
          height: 48px;
          .item-left{
            position: relative;
            .name{
              display: inline-block;
              line-height: 25px;
              font-size: 18px;
              color: #4D4D4D;
              font-weight: 600;
              border-bottom: 1px solid #4D4D4D;
              cursor: pointer;
            }
            .team{
              display: block;
              height: 22px;
              line-height: 22px;
              font-size: 16px;
              color: #3182CE;
            }
          }
          .item-right{
            position: absolute;
            top: 8px;
            right: 0;
            display: flex;
            font-size: 14px;
            .date-box{
              margin-left: 16px;
              width: 66px;
              height: 58px;
              text-align: center;
              background: #F3F3F3;
              color: #C4C4C4;
              border-radius: 4px;
              overflow: hidden;
              span{
                display: block;
              }
              span:nth-child(1){
                line-height: 42px;
                font-size: 16px;
              }
              span:nth-child(2){
                margin-top: -8px;
                margin-left: -50%;
                width: 200%;
                height: 32px;
                line-height: 32px;
                font-size: 20px;
                transform: scale(0.5);
                color: #EBEBEB;
                background: #C4C4C4;
              }
              &.box-blue{
                background: #E9F6FE;
                color: #77CAED;
                span:nth-child(2){
                  color: #E9F6FE;
                  background: #77CAED;
                }
              }
              &.box-red{
                background: #FBEFED;
                color: #DC5E4F;
                span:nth-child(2){
                  color: #FBEFED;
                  background: #DC5E4F;
                }
              }
              &.box-yellow{
                background: #FFF7F4;
                color: #FFB18F;
                span:nth-child(2){
                  color: #FFF7F4;
                  background: #FFB18F;
                }
              }
              &.box-green{
                background: #EEF8F5;
                color: #7CCAB4;
                span:nth-child(2){
                  color: #EEF8F5;
                  background: #7CCAB4;
                }
              }
            }
          }
        }
      }
    }
  }
}
.font-black{
  margin: 4px 0;
  padding-left: 12px;
  line-height: 20px;
  color: #4d4d4d;
  cursor: pointer;
}
.border-red{
  border-left: 4px solid #DC5E4F;
}
.border-yellow{
  border-left: 4px solid #FFB18F;
}
.border-green{
  border-left: 4px solid #7CCAB4;
}
:deep(.ant-select-selection-overflow-item){
  max-width: 100px;
}
</style>
