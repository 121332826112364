<template>
  <section class="todo-info" ref="todoInfoRef">
    <div class="info-top">
      <span class="title">待办事项</span>
    </div>
    <div class="info-main">
      <ul>
        <li>
          <span @click="goTodoList('1')" class="num">{{ state.toolInfo.preMatchNum }}</span>
          <span class="tt">需求匹配</span>
        </li>
        <li>
          <span @click="goTodoList('2')" class="num">{{ state.toolInfo.assignmentsNum }}</span>
          <span class="tt">调令管理</span>
        </li>
        <li>
          <span @click="goTodoList('3')" class="num">{{ state.toolInfo.welcomeLetterNum }}</span>
          <span class="tt">Welcome Letter</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import {useGlobalPropertyHook} from '@/hooks/common'
import { useRouter } from "vue-router";
export default defineComponent({
  name: "TodoInfo",
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const state = reactive({
      toolInfo: {}
    })
    const getUserData = () => {
      $api.todoListinfo().then((res) => {
        state.toolInfo = res.data;
      });
    };
    const init = () => {
      getUserData();
    };
    const goTodoList = (index) => {
      router.push({
        path: '/todoList',
        query: {
          flag: index
        }
      });
    }
    const refresh = () =>{
      init()
    }
    onMounted(()=>{
      init();
    })
    return {
      state,
      goTodoList,
      refresh
    };
  },
});
</script>

<style scoped lang="less">
.todo-info{
  padding: 12px 24px;
  height: 100%;
  font-size: 16px;
  color: #999;
  .info-top{
    .title{
      font-size: 22px;
      font-weight: 600;
      color: #4d4d4d;
    }
  }
}
.info-main{
  ul{
    display: flex;
    margin: 0;
    width: 100%;
    li{
      flex: 1;
      span{
        display: block;
        text-align: center;
        &.num{
          line-height: 50px;
          font-size: 44px;
          font-weight: 600;
          color: #3182CE;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
